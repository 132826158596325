import React from 'react';
import Link from 'next/link';
import ReactGA from 'react-ga4';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useEligibility } from '@/context/base-context';
import { Button } from './button';

export default function SideBarBanner() {
	const { isEligible, isOptOut, setShowOptOutModal } = useEligibility();

	return (
		<div>
			<Swiper
				modules={[Autoplay]}
				loop
				autoplay={{
					delay: 6000,
					disableOnInteraction: false,
				}}
			>
				{isEligible && isOptOut === false && (
					<SwiperSlide>
						<div className="mb-8 lg:mb-6 relative">
							<Button
								size="small"
								className="absolute top-2 right-2 !px-2 !py-1 !text-[10px]"
								onClick={() => {
									setShowOptOutModal(true);
								}}
							>
								Niet tonen
							</Button>
							<Link
								href="https://goud.vriendenloterij.nl/speel-mee-online-kkd?utm_source=keukenkampioendivisie.nl&utm_medium=display&utm_campaign=202408_elftal_vol_toppers_v8424&utm_content=banner&utm_term=ced&beni="
								target="_blank"
								rel="sponsored"
								onClick={() =>
									ReactGA.event({
										category: 'Sponsored Banner',
										action: 'Click',
										label: 'Sidebar banner vriendenloterij augustus 2024',
									})
								}
							>
								<picture>
									<source
										media="(max-width: 600px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/08/WEBSITE_1080X1080.jpg"
									/>
									<source
										media="(min-width: 601px) and (max-width: 768px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/08/WEBSITE_600X300.jpg"
									/>
									<source
										media="(min-width: 769px) and (max-width: 1024px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/08/WEBSITE_1920x1080.jpg"
									/>
									<source
										media="(min-width: 1025px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/08/WEBSITE_1080X1080.jpg"
									/>
									<img
										src="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/08/WEBSITE_1080X1080.jpg"
										alt="Default Image"
									/>
								</picture>
							</Link>
						</div>
					</SwiperSlide>
				)}
				<SwiperSlide>
					<div className="mb-8 lg:mb-6 relative">
						<Link
							href="https://www.keukenkampioen.nl/fanbase"
							target="_blank"
							rel="sponsored"
							onClick={() =>
								ReactGA.event({
									category: 'Sponsored Banner',
									action: 'Click',
									label: 'Fanbase activatie',
								})
							}
						>
							<picture>
								<source
									media="(max-width: 600px)"
									srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/11/KKN-Opmaak-middelen-Fanbase-programma-24-25-_-DTP-5018-ONLINE_Algemeen_1080-x-1080.jpg"
								/>
								<source
									media="(min-width: 601px) and (max-width: 768px)"
									srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/11/KKN-Opmaak-middelen-Fanbase-programma-24-25-_-DTP-5018-ONLINE_Algemeen_1920-x-1080.jpg"
								/>
								<source
									media="(min-width: 769px) and (max-width: 1024px)"
									srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/11/KKN-Opmaak-middelen-Fanbase-programma-24-25-_-DTP-5018-ONLINE_Algemeen_1920-x-1080.jpg"
								/>
								<source
									media="(min-width: 1025px)"
									srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/11/KKN-Opmaak-middelen-Fanbase-programma-24-25-_-DTP-5018-ONLINE_Algemeen_1080-x-1080.jpg"
								/>
								<img
									src="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/11/KKN-Opmaak-middelen-Fanbase-programma-24-25-_-DTP-5018-ONLINE_Algemeen_1080-x-1080.jpg"
									alt="Fanbase"
								/>
							</picture>
						</Link>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
}
